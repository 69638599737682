import { Image, Nav, Table } from "react-bootstrap"
import { useEffect, useState } from "react"
import removeImage from "../../../assets/images/app/remove.svg"
import editImage from "../../../assets/images/app/edit.svg"
import AddEdit from "./AddEdit"
import Delete from "./Delete"
import Moment from "react-moment"


function User({data}) {
    const { name,lastname,email,address,number,full_name, created_at } = data
    //const {name:roleName, id,abbr} = role
    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);

    return (
        <>
            <tr>
                <td>
                    <strong>{full_name}</strong>
                </td>
                <td>
                    { email }
                </td>
                <td>
                    {number}
                </td>
                <td>
                    {address}
                </td>
                <td>
                <Moment format="DD/MM/YYYY HH:mm">{created_at}</Moment>
                </td>
                <td>
                    <Nav style={{ fontSize: '12px' }}>
                        <Nav.Link onClick={() => setModalShow(true)}><Image style={{ filter: 'brightness(10%)'}} src={editImage} /></Nav.Link>
                        <Nav.Link onClick={() => setDeleteModalShow(true)}><Image src={removeImage} /></Nav.Link>
                    </Nav>
                </td>
            </tr>
            <AddEdit
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={data}
                type={'edit'}
            />
            <Delete
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                data={data}
                type={'delete'}
            />
        </>

    )
}
export default User