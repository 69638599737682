import { useState } from 'react';
import { Alert, ButtonGroup, CloseButton, Col, Form, Image, Nav, Row, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import documentImage from "../../../assets/images/app/pdfIcon.png";
import downloadImage from "../../../assets/images/app/download-icon.svg";
import removeImage from "../../../assets/images/app/remove.svg";
import Delete from './Delete';

function Documents(props) {
    const sessionId = props.data?.id;
    const [files, setFiles] = useState([]);
    const [modalShow, setModalShow] = useState(false)
    const [docData, setDocData] = useState({
        id: 0,
        type: props.type || "",
    })

    function deleteDoc(docId, docType) {
        setDocData({
            id: docId,
            type: docType,
        })
        setModalShow(true)
    }

    function renderProtocols() {
        return (
            props.data?.protocols.length > 0 ?
                <Table bordered hover><thead>
                    <tr>
                        <th style={{ width: '85%' }}>
                            Име
                        </th>
                        <th style={{ width: '15%' }}>
                            Опции
                        </th>
                    </tr>
                </thead><tbody>{
                    props.data?.protocols.map((p) => (
                        <tr>
                            <td>
                                <Image src={documentImage} className="material-image" />
                                <span style={{ textAlign: 'center', paddingLeft: '15px' }}>{p.protocol.name}</span>
                            </td>
                            <td>
                                <Nav>
                                    <Nav.Link target='_blank' href={p.protocol.path}><Image style={{ height: '70%', marginRight: '-10px' }} src={downloadImage} /></Nav.Link>
                                    <Nav.Link onClick={() => deleteDoc(p.protocol.id, 'protocols')}><Image style={{ height: '70%', marginRight: '-10px' }} src={removeImage} /></Nav.Link>
                                </Nav>
                            </td>
                        </tr>
                    ))
                }
                    </tbody>
                </Table>
                : <>
                    <Alert variant="warning">
                        <Alert.Heading>Внимание!</Alert.Heading>
                        <p>
                            Към това заседание няма прикачени протоколи!
                        </p>
                    </Alert></>
        )
    }

    function renderMaterials() {
        return (
            props.data?.materials.length > 0 ?
                <Table bordered hover><thead>
                    <tr>
                        <th style={{ width: '85%' }}>
                            Име
                        </th>
                        <th style={{ width: '15%' }}>
                            Опции
                        </th>
                    </tr>
                </thead><tbody>{
                    props.data?.materials.map((p) => (
                        <tr>
                            <td>
                                <Image src={documentImage} className="material-image" />
                                <span style={{ textAlign: 'center', paddingLeft: '15px' }}>{p.material.name}</span>
                            </td>
                            <td>
                                <Nav>
                                    <Nav.Link target='_blank' href={p.material.path}><Image style={{ height: '70%', marginRight: '-10px' }} src={downloadImage} /></Nav.Link>
                                    <Nav.Link onClick={() => deleteDoc(p.material.id, 'materials')}><Image style={{ height: '70%', marginRight: '-10px' }} src={removeImage} /></Nav.Link>
                                </Nav>
                            </td>
                        </tr>
                    ))
                }
                    </tbody>
                </Table>
                : <><Alert variant="warning">
                    <Alert.Heading>Внимание!</Alert.Heading>
                    <p>
                        Към това заседание няма прикачени материали!
                    </p>
                </Alert></>
        )
    }

    return (
        <section>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.data?.name ?? props.data?.event_date} - {props.type === 'protocols' ? 'Протоколи' : 'Материали'}
                    </Modal.Title>
                    <CloseButton onClick={props.onHide} variant='white' />
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">
                        {props.type === 'protocols' ? renderProtocols() : renderMaterials()}
                    </Row>
                </Modal.Body>
            </Modal>
            <Delete
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={docData}
                tab={props.tab}
                type={props.type}
            />
        </section>
    );
}

export default Documents;
