import { useEffect } from "react"
import useFetch from "../../../hooks/useFetch"
import { useFormDataContext } from "../../../providers/form"
import { useCommissionsDataContext } from "../providers/commissions"
import { ENDPOINTS } from "../../../constants/endpoints"

function useCreateEditCommission(commissionId = null,page = null,onHide) {
    const commissionData = useCommissionsDataContext()
    const formData = useFormDataContext()

    const { fetch, loading, validations, handleValidations } = useFetch()

    async function handleSave(data,resetForm) {
        if (loading) return
        handleValidations().clear()

        const endpoint = commissionId ? ENDPOINTS.COMMISSIONS.UPDATE : ENDPOINTS.COMMISSIONS.CREATE
        const method = "post"

        try {
            const response = await fetch({
                endpoint,
                method,
                data,
                headers: { 'Content-Type': 'multipart/form-data' }
            })

            if (response.status === 200) {
                commissionData.refetchData(commissionData.getCurrentPage())
                resetForm()
                onHide()                
            } else {
                console.error('Request failed with status:', response.status);
            }
        } catch (error) {
            console.log("Error:", error);
        }
    }

    return {
        loading,
        handleSave,
        validations,
        handleValidations
    }
}
export default useCreateEditCommission
