import { useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import { useFormDataContext } from "../../../providers/form";
import { ENDPOINTS } from "../../../constants/endpoints";
import { useSessionsDataContext } from "../providers/sessions";
import { usePastSessionsDataContext } from "../providers/pastSessions";
import { useUpcomingSessionsDataContext } from "../providers/upcomingSessions";

function useCreateEditSession(sessionId = null,onHide) {
    const sessionData = useSessionsDataContext();
    const pastSessionData = usePastSessionsDataContext();
    const upcomingSessionData = useUpcomingSessionsDataContext();
    const formData = useFormDataContext();

    const { fetch, loading, validations, handleValidations } = useFetch();

    async function handleSave(data,resetForm,type,isSession=true) {
        if (loading) return;

        handleValidations().clear();

        const endpoint = sessionId ? ENDPOINTS.SESSIONS.UPDATE : ENDPOINTS.SESSIONS.CREATE;
        const method = "post";

        try {
            const response = await fetch({
                endpoint,
                method,
                data,
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.status === 200) {
                //sessionData.refetchData(sessionData.getCurrentPage());
                if(type === 'upcoming') upcomingSessionData.refetchDataUpcoming(upcomingSessionData.getCurrentPageUpcoming());
                if(type === 'past') pastSessionData.refetchDataPast(pastSessionData.getCurrentPagePast());
                sessionId ?? resetForm()
                isSession ?? resetForm()
                onHide()
            } else {               
                console.error('Request failed with status:', response.status);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    return {
        loading,
        handleSave,
        validations,
        handleValidations
    };
}

export default useCreateEditSession;
