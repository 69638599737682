import { Button, CloseButton, Form, Modal } from "react-bootstrap"
import useDeleteCouncilor from "../hooks/useDeleteCouncilor";

function Delete(props) {
    const councilorId = props.data?.id;
    const { handleSave, loading} = useDeleteCouncilor(councilorId);

    const onFormSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('id', props.data?.id);
        handleSave(formData);
    };
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Изтриване
                </Modal.Title>
                <CloseButton onClick={props.onHide} variant='white'/>
            </Modal.Header>
            <Modal.Body>
                <Form id="delete-commission" onSubmit={onFormSubmit}>
                    <Form.Control
                        name='id'
                        type="text"
                        hidden
                        value={props.data.id}
                    />
                    <h4>Сигурни ли сте ?</h4>
                    <p>
                        Предприетите от Вас действия може да са необратими.
                    </p>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" form='delete-commission' type="submit" disabled={loading}>
                    Изтриване
                </Button>
                <Button variant="secondary" onClick={props.onHide}>Затвори</Button>
            </Modal.Footer>
        </Modal>
    )
}
export default Delete