import { Image, Nav, Table } from "react-bootstrap"
import { useEffect, useState } from "react"
import removeImage from "../../../assets/images/app/remove.svg"
import editImage from "../../../assets/images/app/edit.svg"
import testImg from "../../../assets/images/app/manTestImg.jpg"
import defaultAvatar from "../../../assets/images/app/default-avatar.png"
import useConvertMinutes from "../../../hooks/useConvertMinutes"
import AddEdit from "./AddEdit"
import Delete from "./Delete"


function Councilor({roles,data}) {
    const { name,firstname,lastname,email,average_rating,number,is_councilor,path,role_id,replies_within,full_name,role } = data
    //const {name:roleName, id,abbr} = role
    const [rating, setRating] = useState(average_rating)
    const {days,hours,minutes,responseTime} = useConvertMinutes(replies_within)
    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);

    function starRating() {
        return (
            <>
                {[1, 2, 3, 4, 5].map((star) => {
                    return (
                        <span
                            className='start'
                            style={{
                                color: rating >= star ? 'gold' : 'gray',
                                fontSize: `20px`,
                            }}
                        >
                            {' '}
                            ★{' '}
                        </span>
                    )
                })}
            </>
        )
    }

    return (
        <>
            <tr>
                <td>
                    <Image src={path ?? defaultAvatar} roundedCircle style={{ height: '50px', width: '50px' }} />
                </td>
                <td>
                    <strong>{firstname} {lastname}</strong>
                </td>
                <td>
                    { role_id ? (role.name ?? "Член") : "Член"}
                </td>
                <td>
                    {starRating()}
                </td>
                <td>
                    {responseTime != null ? "Отговаря в рамките на "+responseTime : ""}
                </td>
                <td>
                    <Nav style={{ fontSize: '12px' }}>
                        <Nav.Link onClick={() => setModalShow(true)}><Image style={{ filter: 'brightness(10%)'}} src={editImage} /></Nav.Link>
                        <Nav.Link onClick={() => setDeleteModalShow(true)}><Image src={removeImage} /></Nav.Link>
                    </Nav>
                </td>
            </tr>
            <AddEdit
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={data}
                roles={roles}
                type={'edit'}
            />
            <Delete
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                data={data}                
                type={'delete'}
            />
        </>

    )
}
export default Councilor