import { useEffect } from "react"
import useFetch from "../../../hooks/useFetch"
import { useFormDataContext } from "../../../providers/form"
import { useCommissionsDataContext } from "../providers/commissions"
import useFetchCommissions from "./useFetchCommissions"
import { ENDPOINTS } from "../../../constants/endpoints"

function useDeleteCommission(commissionId = null) {
    const commissionData = useCommissionsDataContext()

    const {fetch,loading} = useFetch()

    function handleSave(data) {
        if (loading) return

        const endpoint = ENDPOINTS.COMMISSIONS.DELETE
        const method = "post"
        
        fetch({
            endpoint,
            method,
            data,
        })
        .then(() => {
            commissionData.refetchData(commissionData.getCurrentPage())
        })
    }

    return {
        loading,
        handleSave,
    
    }
}
export default useDeleteCommission
