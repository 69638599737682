import { Button, Col, Container, Pagination, Row, Table } from "react-bootstrap"
import { useEffect, useState } from "react"
import Councilor from "./components/Councilor"
import { useCouncilorsDataContext } from "./providers/councilors"
import NoResults from "../app/components/NoResults"
import Loader from "../app/components/Loader"
import addImage from "../../assets/images/app/add.svg"
import AddEdit from "./components/AddEdit"
import useFetchRoles from "./hooks/useFetchRoles"

function Councilors() {

    const roleData = useFetchRoles();
    const { getRoles } = roleData;
    const roles = getRoles()?.data || [];
    const councilors = useCouncilorsDataContext()
    const { getCouncilors, refetchData, getCurrentPage, setPage, loading } = councilors
    const [modalShow, setModalShow] = useState(false)

    function changePage(newPage) {
        setPage(newPage)
    }

    const currentPage = getCurrentPage();
    const totalPages = getCouncilors().pages;

    function createPagination() {
        const pageItems = [];

        // Always show the first page
        pageItems.push(
            <Pagination.Item key={1} active={1 === currentPage} onClick={() => changePage(1)}>
                1
            </Pagination.Item>
        );

        // Show ellipsis after the first page if necessary
        if (currentPage > 3) {
            pageItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }

        // Show current page and next 2 pages (if they exist) dynamically
        const startPage = Math.max(2, currentPage - 1); // Start from current page or 2 if close to the start
        const endPage = Math.min(currentPage + 2, totalPages - 1); // Ensure no more than 2 pages after the current one

        for (let number = startPage; number <= endPage; number++) {
            pageItems.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => changePage(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        // Show ellipsis before the last page if necessary
        if (currentPage + 2 < totalPages - 1) {
            pageItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }

        // Always show the last page if there are more than one page
        if (totalPages > 1) {
            pageItems.push(
                <Pagination.Item key={totalPages} active={totalPages === currentPage} onClick={() => changePage(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return pageItems;
    }
    let paginatedItems = [];
    for (let number = 1; number <= getCouncilors().pages; number++) {
        paginatedItems.push(
            <Pagination.Item key={number} active={number === getCurrentPage()} onClick={() => changePage(number)}>
                {number}
            </Pagination.Item>,
        );
    }

    function renderTable() {
        return (
            <section>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ width: '3%' }}>
                                Снимка
                            </th>
                            <th style={{ width: '31%' }}>
                                Съветник
                            </th>
                            <th style={{ width: '13%' }}>
                                Роля
                            </th>
                            <th style={{ width: '7%' }}>
                                Оценка
                            </th>
                            <th style={{ width: '36%' }}>
                                Време за отговор
                            </th>
                            <th style={{ width: '10%' }}>
                                Опции
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {getCouncilors().data.map((c) => (
                            <Councilor page={getCurrentPage()} roles={roles} key={c.id} data={c} />
                        ))}
                    </tbody>
                </Table>
                <div>
                    {totalPages > 1 ? (
                        <Pagination style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} centered>
                            {createPagination()}
                        </Pagination>
                    ) : null}
                </div>
            </section>
        )
    }
    function results() {
        return getCouncilors().pages > 0 ? renderTable() : <NoResults type={'councilors'} />
    }


    return (
        <>
            <Container fluid="true" style={{ marginBottom: '25px' }}>
                <Row style={{ width: '100%' }}>
                    <Col style={{ marginLeft: '5px' }}>
                        <h1>Съветници</h1>
                    </Col>
                    <Col style={{ marginRight: '0px' }}>
                        <Button onClick={() => setModalShow(true)} variant="success" style={{ float: 'right', marginTop: '10px', marginRight: '0px' }} >
                            <img style={{ margin: '3px', marginRight: '6px' }} src={addImage} />Добави Съветник
                        </Button>
                    </Col>
                </Row>
            </Container>
            {councilors.loading == false ? results() : <Loader />}
            <AddEdit
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={null}
                roles={roles}
                type={'add'}
            />
        </>

    )
}
export default Councilors